import React from "react"
import PropTypes from "prop-types"
import tw from "twin.macro"
import { graphql } from "gatsby"
import { getImage, withArtDirection } from "gatsby-plugin-image"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import HeroNarc from "@components/heroNarc"
import Anchor from "@components/anchor"
import Container from "@components/container"
import Section from "@components/section"
import BlurredBgCallout from "@components/blurredBgCallout"
import BorderedContent from "@components/borderedContent"
import MediaAndText from "@components/mediaAndText"
import References from "@components/references"

import { Heading2, purpleButton } from "@styles/ui"

// Assets
import bottleIcon from "@images/icons/icon-gradient-bottle.svg"
import moleculeIcon from "@images/icons/icon-gradient-molecule.svg"
import concentrationIcon from "@images/icons/icon-gradient-concentration.svg"

const heroEyebrow = (
  <>
    <span tw="md:(inline-block max-w-[350px])">
      When choosing an oxybate medication to treat cataplexy and/or excessive
      daytime sleepiness&nbsp;(EDS) in narcolepsy
    </span>
  </>
)

const Narcolepsy = ({ data, location }) => {
  const gradientBorderPoints = [
    {
      icon: bottleIcon,
      text: (
        <>
          XYWAV is a lower&#8209;sodium oxybate treatment option for cataplexy
          and/or EDS in patients with narcolepsy.<sup>2,3</sup>
        </>
      ),
    },
    {
      icon: moleculeIcon,
      text: (
        <>
          Inclusion of other cations in the formulation allows for 92% less
          sodium than XYREM per nightly&nbsp;dose.<sup>3</sup>
        </>
      ),
    },
    {
      icon: concentrationIcon,
      text: (
        <>
          XYWAV contains the same active moiety at the same concentration as
          XYREM.<sup>2,6</sup> Both contain 0.413&nbsp;g/mL of oxybate in
          solution.
          <sup>2,5</sup>
        </>
      ),
    },
  ]
  const referenceData = [
    <>
      Ohayon MM. Narcolepsy is complicated by high medical and psychiatric
      comorbidities: a comparison with the general population.{" "}
      <span tw="italic">Sleep Med</span>. 2013;14(6):488-492.
    </>,
    <>
      XYWAV<sup>®</sup> (calcium, magnesium, potassium, and sodium oxybates).
      Prescribing Information. Palo Alto, CA: Jazz Pharmaceuticals, Inc.
    </>,
    <>
      Bogan RK, Thorpy MJ, Dauvilliers Y, et al. Efficacy and safety of calcium,
      magnesium, potassium, and sodium oxybates (lower&#8209;sodium oxybate
      [LXB]; JZP-258) in a placebo-controlled, double-blind, randomized
      withdrawal study in adults with narcolepsy with cataplexy.
      <span tw="italic"> Sleep</span>. 2021;44(3):zsaa206.
    </>,
    <>
      Thorpy MJ. Recently approved and upcoming treatments for narcolepsy.
      <span tw="italic"> CNS Drugs</span>. 2020;34(1):9-27.
    </>,
    <>
      XYREM<sup>®</sup> (sodium oxybate). Prescribing Information. Palo Alto,
      CA: Jazz Pharmaceuticals, Inc.
    </>,
    <>
      US Department of Health and Human Services, US Food and Drug
      Administration website. Clinical superiority findings.
      https://www.fda.gov/industry/designating-orphan-product-drugs-and-biological-products/clinical-superiority-findings.
      Accessed June 28, 2021.
    </>,
    <>
      Jackson SL, King SM, Zhao L, Cogswell ME. Prevalence of excess sodium
      intake in the United States—NHANES, 2009-2012.
      <span tw="italic"> MMWR Morb Mortal Wkly Rep</span>.
      2016:64(52):1393-1397.
    </>,
  ]

  const heroImg = withArtDirection(getImage(data.heroDesktop), [
    {
      media: "(max-width: 767px)",
      image: getImage(data.heroMobile),
    },
  ])

  return (
    <Layout location={location} lowerVA={true}>
      <Seo
        title="Xywav for Narcolepsy Treatment | XYWAV HCP"
        description="Talk to your patients about XYWAV® (calcium, magnesium, potassium, and sodium oxybates) as an oxybate treatment for cataplexy and/or excessive daytime sleepiness in narcolepsy. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <HeroNarc
        imgSrc={heroImg}
        eyebrowText={heroEyebrow}
        bgColour="blue"
        altText="A doctor talking to her potential XYWAV patient."
        mobileHeight="60"
      >
        <h1 tw="uppercase gradient1 bg-clip-text text-transparent font-black text-[40px] leading-[1.2] md:(max-w-[380px]) xl:(text-[48px])">
          It&apos;s time to address the elephant in the room
        </h1>
        <p tw="mt-1.5 md:max-w-[200px] lg:(max-w-[300px]) xl:(max-w-[470px])">
          Talk to your patients about excessive sodium in oxybate&nbsp;treatment
        </p>
        <Anchor
          link="/narcolepsy/sodium-content/"
          addedStyles={[
            purpleButton,
            tw`hidden md:(flex my-[25px] max-w-[370px])`,
          ]}
        >
          Sodium intake & XYWAV
        </Anchor>
      </HeroNarc>

      <Section type="primary">
        <Container>
          <Anchor
            link="/narcolepsy/sodium-content/"
            addedStyles={[purpleButton, tw`mb-[30px] md:(hidden)`]}
          >
            Sodium intake & XYWAV
          </Anchor>

          <h2 css={[Heading2, tw`mb-5 xl:(mb-[25px])`]}>
            Why? Because people living with narcolepsy are at 2x greater risk
            for heart diseases.<sup>1*</sup>
          </h2>

          <ul tw="padding-inline-start[16px] mb-5">
            <li tw="relative mb-1.5 before:(content['&bull;'] absolute left-[-15px] top-0)">
              XYWAV—the first and only lower&#8209;sodium oxybate indicated to
              treat cataplexy and excessive daytime sleepiness in narcolepsy
              <sup>2-4</sup>—has 131&nbsp;mg of sodium per maximum 9 g nightly
              dose
              <sup>2,3</sup>
            </li>
            <li tw="relative mb-1.5 before:(content['&bull;'] absolute left-[-15px] top-0)">
              Every night, that&apos;s a reduction of ~1509&nbsp;mg of sodium
              compared to high&#8209;sodium oxybate (XYREM<sup>&reg;</sup>{" "}
              [sodium oxybate] oral solution) at the equivalent maximum dose
              <sup>2,3,5</sup>
            </li>
          </ul>
          <p tw="text-[12px] mb-[25px] text-indent[-4px] pl-[5px]">
            *Odds ratio (95% CI) 2.07 (1.22-3.51); P&#8209;value &lt;0.01. The
            odds ratio is from an interview-based study of 320 patients with
            narcolepsy compared to a matched general population sample of
            1464&nbsp;participants.<sup>1</sup>
          </p>

          <Anchor
            link="/narcolepsy/comorbidities/"
            addedStyles={[
              purpleButton,
              tw`max-w-[500px] mx-auto mb-[30px] md:(w-[100%]) xl:(mb-10)`,
            ]}
          >
            Narcolepsy & Potential Comorbidities
          </Anchor>

          <BlurredBgCallout>
            <p css={[Heading2, tw`mb-4 xl:(max-w-[90%])`]}>
              XYWAV is clinically superior to XYREM by means of greater
              cardiovascular (CV) safety due to a greatly reduced chronic sodium
              burden<sup>6&#10013;&#xFE0E;</sup>
            </p>
            <p tw="mb-4 xl:(mb-1.5)">
              FDA has determined XYWAV to be clinically superior to XYREM,
              indicating that<sup>6&#10013;&#xFE0E;</sup>:
            </p>
            <ul tw="padding-inline-start[20px] mb-[25px] xl:(mb-5)">
              <li tw="relative mb-1 before:(content['&bull;'] absolute left-[-15px] top-0)">
                XYWAV is clinically superior to XYREM by means of greater safety
                because XYWAV provides reduced chronic sodium burden
              </li>
              <li tw="relative mb-1 before:(content['&bull;'] absolute left-[-15px] top-0)">
                The difference in sodium content between XYWAV and XYREM at
                recommended doses will be clinically meaningful in reducing
                cardiovascular morbidity in a substantial proportion of patients
                for whom the drug is indicated
              </li>
            </ul>
            <p tw="text-[12px] mb-[25px] text-indent[-4px] pl-[5px]">
              <sup>&#10013;&#xFE0E;</sup>The decision of the FDA Office of
              Orphan Products Development (OOPD) is based on findings that XYWAV
              provides a greatly reduced chronic sodium burden compared to
              XYREM. There are no head&#8209;to&#8209;head data for XYWAV and
              XYREM.
            </p>
            <Anchor
              link="https://investor.jazzpharma.com/news-releases/news-release-details/jazz-pharmaceuticals-announces-orphan-drug-exclusivity-xywavtm"
              newTab
              isExternal
              underline
              addedStyles={tw`font-semibold`}
            >
              See the press release &gt;
            </Anchor>
          </BlurredBgCallout>

          <h2 css={[Heading2, tw`mt-[30px] xl:(mb-[35px] mt-[50px])`]}>
            Sodium intake is a modifiable CV disease risk factor<sup>7</sup>
          </h2>

          <div tw="xl:(flex column-gap[30px])">
            {gradientBorderPoints &&
              gradientBorderPoints.map((item, i) => (
                <div key={i} tw="flex-1 text-center mt-[30px] xl:(mt-0)">
                  <BorderedContent
                    narcolepsy
                    thinBorder
                    addedStyles={tw`flex flex-col items-center py-10`}
                  >
                    <img src={item.icon} alt="" tw="w-[70px] h-[70px]" />
                    <p tw="mt-[35px] xl:(mt-[25px] max-w-[88%])">{item.text}</p>
                  </BorderedContent>
                </div>
              ))}
          </div>
        </Container>
      </Section>

      <MediaAndText
        eyebrowText="Watch video"
        text="Hear how experts discuss XYWAV with their patients"
        videoImage={data.videoImage}
        videoId="x9tuesq5lt"
        altText="Hear how experts discuss XYWAV with their patients."
        anchor="xywavkol"
      />

      <Section type="primary">
        <Container addedStyles={tw`xl:(flex justify-center column-gap[120px])`}>
          <Anchor
            addedStyles={[
              purpleButton,
              tw`max-w-[460px] mx-auto mb-[30px] py-2.5 px-11 xl:(max-w-[unset] mx-[unset] min-w-[435px] px-10 mb-0)`,
            ]}
            link="/narcolepsy/transitioning-from-xyrem/"
          >
            Transitioning from XYREM to XYWAV
          </Anchor>
          <Anchor
            addedStyles={[
              purpleButton,
              tw`max-w-[460px] mx-auto py-2.5 px-11 xl:(max-w-[unset] mx-[unset] min-w-[435px] px-10)`,
            ]}
            link="/narcolepsy/dosing-and-titration/"
          >
            Dosing & titrating for new starts
          </Anchor>
        </Container>
      </Section>

      <References referenceData={referenceData} />
    </Layout>
  )
}

export default Narcolepsy

Narcolepsy.propTypes = {
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query NarcQuery {
    heroMobile: file(
      relativePath: {
        eq: "narcolepsy-page/doctor-speaking-with-patient-mobile.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    heroDesktop: file(
      relativePath: { eq: "narcolepsy-page/doctor-speaking-with-patient.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
    videoImage: file(
      relativePath: {
        eq: "narcolepsy-page/experts-discuss-xywav-video-screenshot.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
